import { MotionConfig } from 'framer-motion';
import GetStarted from '../components/landing/getStarted';
import OurClients from '../components/landing/ourClients';
import OurCommunity from '../components/landing/ourCommunity';
import OurServices from '../components/landing/ourServices';
import RequestSection from '../components/landing/request';
import Testimonials from '../components/landing/testimonials';
import { LandingStyle } from '../styles/landingStyle';
import HeroSection from '../components/landing/hero';

export default function Home() {
  return (
    <main>
      <MotionConfig transition={{ duration: 0.75 }}>
        <LandingStyle >
          <HeroSection />
          <OurClients />
          <OurServices />
          <GetStarted />
          <OurCommunity />
          <Testimonials />
          <RequestSection />
        </LandingStyle>
      </MotionConfig>
    </main>
  );
}